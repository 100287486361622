<template>
  <div v-if="user" class="flex flex-col gap-8 bg-white">
    <!-- <h2 class="mb-4 text-primary text-2xl md:text-3xl text-left">
      <b>Información</b>
    </h2> -->
    <div>
      <h3 class="text-lg text-left text-primary">
        <b>Información de la entidad:</b>
      </h3>
      <BaseInput
        v-if="me"
        type="text"
        label="Nombre de la entidad"
        placeholder=""
        v-model="me.company_name"
        :disabled="true"
      />
    </div>

    <div>
      <h3 class="text-lg text-left text-primary">
        <b>Información del perfil:</b>
      </h3>
      <BaseInput
        type="text"
        label="Nombre completo"
        placeholder=""
        v-model="user.displayName"
        :disabled="true"
      />
      <BaseInput
        v-if="user.phoneNumber"
        type="text"
        label="Número de celular"
        v-model="user.phoneNumber"
        :disabled="true"
      />
      <BaseInput
        v-if="user.email"
        type="text"
        label="Correo electrónico"
        v-model="user.email"
        :disabled="true"
      />
    </div>
    <div>
      <h3 class="text-lg text-left text-primary"><b>Cambiar contraseña:</b></h3>
      <form>
        <BasePassword
          label="Actual contraseña"
          v-model="current_password"
          placeholder="Contraseña"
        />
        <BasePassword
          label="Nueva contraseña"
          v-model="newPassword"
          placeholder="Contraseña"
          :legend="true"
        />
        <BasePassword
          label="Verificar nueva contraseña"
          v-model="verifyPassword"
          :legend="true"
          placeholder="Verifcar contraseña"
        />
        <p v-if="feedback" class="mt-4 text-xs text-red-500">{{ feedback }}</p>
        <Button class="mt-5" type="button" style="primary" :block="true">
          Cambiar Contraseña
        </Button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      current_password: '',
      newPassword: '',
      verifyPassword: '',
      feedback: '',
      type_identification_name: '',
    }
  },
  methods: {
    async changePassword() {
      let id = this.user.id
      let form = {
        current_password: this.current_password,
        password: this.newPassword,
      }

      if (this.$validator.isEmpty(this.current_password)) {
        this.feedback = 'Debe ingresar la contraseña actual'
        return
      } else if (this.$validator.isEmpty(this.newPassword)) {
        this.feedback = 'Debe ingresar una nueva contraseña'
        return
      } else if (this.newPassword != this.verifyPassword) {
        this.feedback = 'Las contraseñas no coinciden'
        return
      } else if (!this.$validator.isStrongPassword(this.newPassword + '')) {
        this.feedback = 'La nueva contraseña no cumple los requisitos'
        return
      } else {
        this.feedback = ''
      }

      let { data, error } = await this.$api.updateUserPassword(id, form)

      if (error) {
        error.error == 'invalid current password'
          ? (this.feedback = 'La contraseña actual es inválida')
          : (this.feedback = 'Error al actualizar la contraseña')
        this.$showNotification('error', 'Error al actualizar la contraseña')
      }
      if (data) {
        this.current_password = ''
        this.newPassword = ''
        this.verifyPassword = ''
        this.$showNotification(
          'success',
          'Se actualizó correctamente la contraseña'
        )
      }
    },
  },
  computed: {
    ...mapState('session', ['user', 'me']),
  },
}
</script>

<style></style>
